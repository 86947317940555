body {
  height: 100vh;
  scrollbar-width: none !important;
}

.bg-button{
  background-color: #ffad70 !important;
  border: none !important;
  color: #000;
  border-radius: 5px;
  
}
.pointer{
  cursor: pointer !important;
}

.page-break {
  page-break-after: always; /* For browsers */
  break-after: always; /* For printing */
  height: 0; /* Ensures no space is taken */
}




.main-section-loa {
  background-image: url('../images/cert/Letter-Head.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 794px;
  height: 1123px;
  overflow: hidden;
  padding-top: 10%;
  padding-bottom: 10%;
}

/* Cerificate css start */
.op-7 {
  opacity: .6;
}
.op-8{
  opacity: .7;
}
.op-1{
  opacity: 1 !important;
}
.main-section {
  background-image: url('../images/img/insternship.png');
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
  width: 1154px;
  height: 816px;
  overflow: hidden;
  padding-top: 12%;
}
.main-section-p{
  background-image: url('../images/img/participation.png');
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
  width: 1154px;
  height: 816px;
  overflow: hidden;
  padding-top: 12%;
}
.main-section-c{
  background-image: url('../images/img/training-bg.png');
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
  width: 1154px;
  height: 816px;
  overflow: hidden;
  padding-top: 12%;
}
.main-section-a{
  background-image: url('../images/img/certificate-ca.png');
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
  width: 1154px;
  height: 816px;
  overflow: hidden;
  padding-top: 12%;
}

.head-1 {
  font-size: 55px;
  color: #525252;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: "Roboto Slab", Rockwell, "Rockwell Nova", var(--bs-font-sans-serif);
  line-height: 0.8;
}
.head-1-1 {
  font-size: 55px;
  color: #1f1f1f;
  font-weight: 550;
  letter-spacing: 2px;
  font-family: "Roboto Slab", Rockwell, "Rockwell Nova", var(--bs-font-sans-serif);
  line-height: 0.8;
}
.head-3{
  font-size: 45px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 0.8;
}
.head-3-1{
  font-size: 22px;
  font-weight: 500;
  color: #cf9d15;
  letter-spacing: 2px;
  font-family: "Roboto Slab", Rockwell, "Rockwell Nova", var(--bs-font-sans-serif);
}
.head-2 {
  font-size: 65px;
  font-weight: 500;
  color: #a6723a;
  font-family: cursive;
  font-style: italic;
  width: fit-content;
}
.head-2-1 {
  font-size: 65px;
  font-weight: 500;
  color: #a6723a;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-family: cursive;
  font-style: italic;
}
.head-4{
  background-color: #00272e;
  color: #fff;
  letter-spacing: 3px;
  font-size: 20px;
  padding: 5px 50px;
  font-weight: 100;
}
.para{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
}
.para-1{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  font-size: 13px;
  letter-spacing: 2px;
}
.sign{
  border-top: 1px solid #000;
}
.sign-1{
  border-top: 2px solid #bf9e43;
}
.sign-2{
  border-bottom: 2px solid #b4953f;
}

/* end */



/* .body-data{
  background-color: #f5f4f3 !important;
} */
.testing-camera{
  margin-top: -38px;
  margin-right: -36px;
}

.profile-layout  .profile-img {
  object-fit: cover;
  /* border: 3px solid black !important; */
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  border-radius: 50% !important;
  overflow: hidden;
}

/* Training css start*/
.training-certificate-wrapper {
  width: 842px;
  height: auto;
  margin: 40px auto 0;
  position: relative;
}
.training-name {
  position: absolute;
  font-family: arial;
  font-weight: 400;
  top: 222px;
  left: 172px;
  font-size: 76px;
  letter-spacing: 1.2px;
  color: #000 ;
  border: 0;
  width: 500px;
  background: inherit;
  text-align: center;
}
.custom-accordion-header {
  color: #000 !important; /* Override any existing styles */
}


.training-text-info {
  position: absolute;
  font-family: arial;
  font-weight: 400;
  top: 340px;
  left: 180px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.2px;
  color: #000;
  max-width: 490px;
  border: 0;
}

/* Training css end */
/* Par start */
.par-certificate-wrapper {
  width: 842px;
  height: auto;
  margin: 40px auto 0;
  position: relative;
}
.par-name {
  position: absolute;
  /* font-family: arial; */
  font-weight: 400;
  top: 226px;
  left: 170px;
  font-size: 76px;
  letter-spacing: 1.2px;
  color: #000;
  border: 0;
  width: 550px;
  background: inherit;
  font-family: ui-sans-serif;
}

.par-lead-text-info {
  position: absolute;
  font-family: arial;
  font-weight: 400;
  top: 340px;
  left: 150px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.2px;
  color: #000;
  max-width: 440px;
  border: 0;
  width: 660px;
}
.par-text-info {
  position: absolute;
  font-family: arial;
  font-weight: 400;
  top: 378px;
  left: 88px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.2px;
  color: #000;
  max-width: 440px;
  border: 0;
  width: 660px;
}

.par-date {
  position: absolute;
  font-size: 18px;
  bottom: 126px;
  right: 360px;
  font-weight: 600;
}
/* Par end */
.certificate-wrapper {
  width: 842px;
  height: auto;
  margin: 40px auto 0;
  position: relative;
}
.intenship-name {
  position: absolute;
  font-family: arial;
  font-weight: 400;
  top: 310px;
  left: 286px;
  font-size: 40px;
  letter-spacing: 1.2px;
  color: #000;
  border: 0;
  width: 550px;
  background: inherit;
}

.intenship-text-info {
  position: absolute;
  font-family: arial;
  font-weight: 400;
  top: 386px;
  left: 284px;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1.2px;
  color: #000;
  max-width: 440px;
  border: 0;
  width: 660px;
}
.intenship-person-name, .intenship-designation, .intenship-date {
  position: absolute;
  font-size: 14px;
  }
  .intenship-person-name {
  bottom: 100px;
  left: 284px;
  }
  .intenship-date {
  bottom: 100px;
  right: 235px;
  }

/* Loader CSS */ 
.loader-block { position: absolute; background-color: rgba(250, 250, 250, 0.8); width: 100%; height: 100%; z-index: 99; top: 0px; display: none; .loader-position { position: absolute; content: ""; transform: translate(-50%, -50%); top: 50%; left: 50%; z-index: 9; .loaders { width: 89px; height: 89px; border: 9px solid rgba(211, 222, 227, 1); border-bottom-color: rgba(242, 93, 74, 1); border-radius: 50%; animation: rotation 1s linear infinite; } } @keyframes rotation { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } }

.primary-color {
  color: #ffad70;
}

.all-domains-pos-inn{
  position: absolute;
  bottom: -4%;
}
.form-control:focus{
  box-shadow: none !important;
}
.alldomain-hover img.card-img-top {
  transition: transform .8s ease;
}
.alldomain-hover:hover img.card-img-top{
  transform: scale(1.1);
}
.alldomain-img-hover{
  position: relative;
  overflow: hidden;
}

/* --> */
.img-animation{
  transition: transform .8s ease !important;
}

.testimonial-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  border-top: 1px solid;
  /* background-color: rgba(0, 0, 0, 0.5);  */
}
.register-font {
  font-weight: bold;
  font-size: 18px;
}
.button4 {
  background-color: white;
  color: black;
  border: 2px solid #e7e7e7;
  border-radius: 8px;
}
.faq-font{
  font-family: sans-serif;
  font-size: 21px;
}

.card-talkourword {
  position: relative;
  /* Set position relative for the parent card */
}

.primary-color-two {
  color: #ec8e49;
}

.custom-primary-color {
  color: #46b3f8;
}

.custom-secondary-color {
  color: #87db62;
}

.guide-custom-color {
  color: #f9c210;
}

/* .button-arrows{
    font-size: "30px" !important;
    border: "2px solid" !important;
    border-radius: "50%" !important;
    padding: "3px" !important;
    cursor: "pointer";
} */

.card-talkourword {
  width: 100%;
  position:absolute;
  min-height: 300px !important;
}

.primary-bg {
  background-color: #1d130f !important;
}

.card-primary-bg {
  background-color: #241a17 !important;
}

.slick-slide > div {
  margin: 0 20px;
}

.active {
  color: #ff8429;
  border-bottom: 3px solid #ff8429 !important;
  color: white !important;
  border-radius: 1px !important;
  position: relative;
  padding: auto 0px !important;
  font-weight: bold !important;
}
.active_one{
  /* color: #ff8429; */
  border-bottom: 3px solid #ff8429 !important;
  color: black !important;
  border-radius: 1px !important;
  position: relative;
  /* padding: auto 0px !important; */
  font-weight: bold !important;
}

.nav_user{
  color: black !important;
  font-size: 20px !important;
}

.navbar-expand-lg{
  background-color:#241a17 !important ;
}

.nav-item .nav-header{
  font-weight: bold;
  color: #241a17 !important;
  color: white !important;
  /* background-color: #241a17 ; */
}

.menu-margin {
  position: relative;
  margin-left: 30% !important;
}

.menu-margin a li {
  padding: 0px 20px;
}



.in-active {
  color: #696866 !important;
}

.primary-btn:hover {
  background-color: #ff8429 !important;
  color: white !important;
  border: solid 1px #ff8429 !important;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}
.custom-progress-bar {
  background-color: #ff8429 !important;
}
button{
  transition: color 0.5s, border-color 0.5s, font-weight 0.5s; /* Apply transition to color, border-color, and font-weight */
}
.secondary-btn {
  transition: color 0.5s, border-color 0.5s, font-weight 0.5s; /* Apply transition to color, border-color, and font-weight */
}
.secondary-btn:hover,
.btn-learn-more:hover {
  color: #ff8429;
  border-color: #ff8429;
  transition: color 0.5s, border-color 0.5s, font-weight 0.5s; /* Apply transition to color, border-color, and font-weight */
}

.footer-menu-list-item {
  color: #696866 !important;
  font-size: 14px;
  cursor: pointer;
}
.footer-data{
  text-decoration: none !important;
}

.footer-menu-list-item:hover {
  color: #c4c4c4 !important;
}

.secondary-bg {
  background-color: #ffad70 !important;
}
.scroll-bar-width-none {
  scrollbar-width: none !important;
}

.secondary-color {
  color: #c4c4c4;
  font-size: 18px !important;
  /* font-weight: bold; */
}


/* .trending-domain-card-image {
  height: 300px !important;
  object-fit: cover;
} */

.header-logo-image {
  height: 20px;
  width: 20px;
}

.road-map-image {
  height: 510px;
  object-fit: cover;
  width: 100%;
}

.take-our-section-right-image {
  height: 92px;
  width: 200px;
  object-fit: cover;
}

.take-our-section-card-image {
  height: 45px;
  width: 100px;
  object-fit: contain;
}
.sticky {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Adjust z-index as needed */
  border-bottom: 1px solid white;
  color: #241a17 !important;
  /* box-shadow: 0 2px 4px rgba(36, 26, 23, 0.5); */
}



.google-image {
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

.animation-scroll-container {
  overflow: hidden !important;
}

.animation-scroll-container::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar */
}

.animation-track {
  display: flex;
  animation: scrollAnimation 20s linear infinite alternate;
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.width-max-content {
  width: max-content !important;
}

.list-style-type-none {
  list-style: none !important;
}

/* .form-control{
    border-top:0px !important;
    border-left:0px !important;
    border-right:0px !important;
    background:transparent !important;
    border-color: white !important;
    color:white !important;
    border-radius: 0px !important;
}
.form-control:focus{
    box-shadow: none !important;
}

.form-control.custom-input:hover{
    background-color: #413a38 !important;
} */
.footer-logo-image {
  right: 20%;
  top: -20px;
}

.table_new_ui th {
  border: none;
}

.table_new_ui th {
  /* background-color:#00696C !important; */
  background-color: #1d130f !important;
  color: white !important;
}

.table-header {
  /* background-color:#00696C !important; */
  background-color: #1d130f !important;
  color: white !important;
}

.table_new_ui th,
.table_new_ui td {
  font-size: 12px;
  color: #333333;
  font-weight: 400;
}

.about-side-image {
  height: 200px;
  width: 300px;
  border-radius: 10%;
}

.about-main-image {
  height: 400px;
  width: 300px;
  border-radius: 10%;
  object-fit: cover;
}

.leader-image,
.leader-image-one {
  filter: grayscale(1);
}

.leader-image:hover {
  filter: none;
}

.leader-image-one:hover {
  filter: none;
}

.leader-image-container:hover {
  filter: none !important;
  color: #ff8429 !important;
}

.leader-image-container .title:hover {
  color: #ff8429 !important;
}

.form-select {
  background-color: transparent !important;
  --bs-form-select-bg-img: url("./../images/down-arrow.png") !important;
}

select option {
  background-color: transparent !important;
}

.accordion-item {
  background-color: transparent !important;
  color: white !important;
  text-align: start;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: white !important;
  text-align: start;
}

.accordion-button {
  background-color: transparent !important;
  color: white !important;
  border: 1px !important;
}

.accordion-button::after {
  background-image: url("./../images//down-arrow.png") !important;
}

.fs-14 {
  font-size: 14px;
}
.faq-fontfamily{
  font-weight: bold;
  font-size: 23px !important;
}
.hero-section{
  font-size: 64px;
  font-weight: bold;
}
.medium-header{
  font-size: 38px !important;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}
.ambassador-header{
  font-size: 48px !important;
  font-weight: bold; 
}
.step-one{
  font-size: 24px;
  font-weight: bold;
}
.with-border-right {
  border-right: 3px solid #ff8429;
}

.acquire-skills-video {
  width: 70% !important;
}

@media (max-width: 767.99px) {
  .menu-margin {
    position: relative;
    margin-left: 0% !important;
  }
  .about-side-image {
    height: 80px;
    width: 100px;
    border-radius: 10%;
  }

  .about-main-image {
    height: 160px;
    width: 100px;
    border-radius: 10%;
    object-fit: cover;
  }

  .leader-image {
    width: 90% !important;
  }

  .ambassador-image {
    width: 100% !important;
  }

  .curriculum-accordion {
    width: 100% !important;
  }
}

.total-users {
  background-color: #0c7eaa;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.total-logins {
  background-color: #89a60f;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.total-payments {
  background-color: #22241b;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.curriculum-accordion {
  width: 75%;
}

.ambassador-image {
  width: 100% !important;
}

.navbar-toggler-icon {
  background-image: url("./../images/menu.png") !important;
}

.user-profile-image {
  height: 30px !important;
  width: 30px !important;
  border-radius: 50%;
}

/* user dahsboard css starts */

.sidebar_a {
  height: calc(100vh - 68px) !important;
  border-right: 1px solid #f5821f;
  background-color: #1d130f !important;
  color: white;
}

.content-scroll {
  height: calc(100vh - 68px);
  overflow-y: auto;
}

.sidemenu-list-item.active li {
  background-color: #412a22;
  color: white !important;
}

.sidemenu-item:hover {
  background-color: #412a22;
  color: white !important;
}

.learning-full-container {
  background-color: #1d130f !important;
}

.content-bg {
  background-color: #fdfdfd !important;
  /* background-color: #C4C4C4 !important; */
}

.search {
  height: 31px !important;
}

.search-position {
  position: relative;
  top: -29px;
  left: 45%;
  padding: 6.5px 5px;
  cursor: pointer;
}
.nav-link.nav-header.active, .nav-link:hover, .nav-link:focus {
	display: inline-block;
	width: auto;
}




@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* body {
    background: #1d130f;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    padding: 50px 0;
} */
a {
    color: #ffffff;
    transition: .3s;
}
a:hover {
    color: #ffffff;
    transition: .3s;
}
div.container {
    max-width: 76%;
}
.course-card {
    background-color: #1d130f;
    width: 40%;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin: 0 -10% 0 0;
    float: left;
}
.second-card {
    margin-top: 7%;
}
.third-card {
    margin-top: 14%;
}
.course-card:hover {
    transform: translateY(-3px);
    -webkit-box-shadow: 0px 0px 56px -32px rgb(232 87 33);
    -moz-box-shadow: 0px 0px 56px -32px rgb(232 87 33);
    box-shadow: 0px 0px 56px -32px rgb(232 87 33);
    
}
.course-card img {
    width: 100%;
    object-fit: cover;
}
.card-content {
    padding: 20px;
    border-right: solid 1px #4e4e4e;
    border-bottom: solid 1px #4e4e4e;
    border-left: solid 1px #4e4e4e;
}
.tag,
.seat {
    background-color: none;
    border: solid 1px #ffffff;
    color: #fff;
    font-size: 12px;
    margin: 0 5px 0 0;
    padding: 5px 12px;
    display: inline-block;
}
.seat {
    background-color: #FFC69D;
    border: none;
    float: right;
    color: #1D130F;
    padding: 5px 15px;
}
.card-title {
    margin: 10px 0 10px;
    font-size: 20px;
}
.card-text {
    margin-bottom: 20px;
    color: #C4C4C4;
    font-size: 14px;
    min-height: 65px;
}
.alldomain-hover .card-text {
  min-height: 60px;
}
.btn-learn-more {
    background-color: #fff;
    border: none;
    padding: 6px 26px;
    font-weight: 600;
}
.card.primary-bg.text-white .card-body p.card-text.secondary-color {
  min-height: 90px;
}
.tab-cnt-span {
	display: contents;
}
.diff-video{
  height: 350px;
}

/* Responsive */
@media only screen and (min-width: 1361px) and (max-width: 1440px){
  .card-talkourword {
    min-height: 320px !important;
  }
}
@media only screen and (min-width: 801px) and (max-width: 1360px){
.tags {
        display: inline-block;
    }
    .tag{
        margin-bottom: 10px;
        padding: 5px 10px;
        float: left;
        font-size: 10px;
    }
    .seat{
        float: left;
        padding: 5px 10px;
        font-size: 10px;
    }
    .card-text {
      font-size: 13px;
    }
    .hero-section {
      font-size: 52px;
    }
    .menu-margin {
      margin-left: 16% !important;
    }
    
  .tab-cnt-span{
    display: contents;
  }
  .trending-domain-card-image {
    height: auto !important;
  }
  .card-title {
    margin: 0px 0 10px;
    font-size: 15px;
  }
  .card-talkourword {
    min-height: 260px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1360px){
  .card-talkourword {
    min-height: 300px !important;
  }
  .card.primary-bg.text-white .card-body p.card-text.secondary-color {
    min-height: 110px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.9px){
  .tag, .seat {
    padding: 5px 5px;
    font-size: 11px;
  }
  .diff-video {
    height: auto;
  }
  .hero-section {
    font-size: 42px;
    font-weight: bold;
    line-height: 56px;
  }
  .medium-header {
    font-size: 30px !important;
  }
  .tab-cnt-span{
    display: contents;
  }
  .navbar-toggler.order-1 {
    border: none;
    outline: none !important;
    box-shadow: inherit !important;
    top: 2%;
    right: 3%;
    position: fixed;
  }
  .trending-domain-card-image {
    height: auto !important;
  }
  .card-title {
    margin: 15px 0 10px;
    font-size: 15px;
  }
  .step-one {
    font-size: 16px;
  }
  .card-talkourword {
    min-height: 405px !important;
  }
  .faq-display{
    display: contents;
  }
  .card.primary-bg.text-white .card-body p.card-text.secondary-color {
    min-height: 110px;
  }
  .alldomain-hover .card-text {
    min-height: 90px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 767.9px){
  .tag, .seat {
    padding: 5px 5px;
    font-size: 11px;
  }
  .raodmap-list {
    list-style: circle;
    display: inline list-item;
  }
  .diff-video {
    height: auto;
  }
  nav.navbar .navbar-brand img {
    width: 180px !important;
  }
.course-card{
    width: 100%;
}
.second-card,
.third-card{
    margin-top: 20px;
}
.tags {
    display: inline-block;
    width: 100%;
}
.tag{
    margin-bottom: 10px;
    float: left;
    padding: 5px 10px;
}
.students-image {
	max-width: 100%;
}
.navbar-toggler.order-1 {
	border: none;
	outline: none !important;
	box-shadow: inherit !important;
  top: 2%;
  right: 3%;
  position: fixed;
}

.hero-section {
  font-size: 32px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  line-height: 38px;
  margin: 20px 0 0;
}
button.bg-white.px-3.py-2.secondary-btn {
	font-size: 20px;
	font-weight: 600;
	padding: 8px 20px !important;
}
.medium-header {
	font-size: 24px !important;
	line-height: 28px;
}
.secondary-color {
	font-size: 14px !important;
}
.faq-fontfamily {
	font-size: 16px !important;
}
.tab-cnt-span{
  display: contents;
}
.ambassador-header {
	font-size: 24px !important;
}
.trending-domain-card-image {
	height: auto !important;
}
.card-title {
	margin: 0px 0 10px;
	font-size: 15px;
}
.step-one {
	font-size: 18px;
}
.all-domains-pos-inn {
	bottom: -1%;
}
.card-talkourword {
  min-height: 280px !important;
}
.acquire-skills-video {
	width: 100% !important;
}
.faq-display{
  display: contents;
}
.faq-btn-sha{
  box-shadow: none !important;
  padding-bottom: 10px !important;
}
.alldomain-hover .card-text {
  min-height: 90px;
}
}